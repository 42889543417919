import { useState, Component } from 'react'
import { NavLink, Link } from "react-router-dom";
import $ from 'jquery'

import ic_gemvault from '../assets/assets-gemvault/ic-gemvault.svg'
import ic_help from '../assets/assets-gemvault/ic-help.svg'
import ic_logo_green from '../assets/assets-gemvault/ic-logo-green.svg'
import ic_search from '../assets/assets-gemvault/ic-search.svg'
import ic_help_white from '../assets/assets-gemvault/ic-help-white.svg'
import ic_bnb from '../assets/assets-gemvault/ic-bnb.png'
import ic_dgm from '../assets/assets-gemvault/ic-dgm.png' 


function Gemvault(){
    require('../css/gemvault.css')

    const [mobileShow, setMobileShow] = useState(false)
    const handleMobileShow = () => {
        setMobileShow(prev => !prev)
    }
    const [panelShow, setPanelShow] = useState(false)
    const handlePanelShow = () => {
        setPanelShow(prev => !prev)
    }
    const [panelShow2nd, setPanelShow2nd] = useState(false)
    const handlePanelShow2nd = () => {
        setPanelShow2nd(prev => !prev)
    }
    const [panelShow3nd, setPanelShow3nd] = useState(false)
    const handlePanelShow3nd = () => {
        setPanelShow3nd(prev => !prev)
    }
    const [panelShow4nd, setPanelShow4nd] = useState(false)
    const handlePanelShow4nd = () => {
        setPanelShow4nd(prev => !prev)
    }
    return (
        <>
        <header>
            <div className="container">
            <nav className="navbar">
                <a href="#" className="nav-logo">
                    <img src={ic_gemvault} alt=""/>
                </a>
                <ul className={`nav-menu ${mobileShow ? "show" : ""}`}>
                    <li className="nav-item">
                        <span href="#" className="nav-link t-white t-700">$3.02</span>
                    </li>
                    <li className="nav-item">
                        <button className="cursor btn-connect btn-green t-green cursor">Connect Wallet</button>
                    </li>
                    <li className="nav-item">
                        <button className="cursor btn-logout t-white t-500 t-16-24 cursor">Log Out</button>
                    </li>
                </ul>
                <div className={`hamburger cursor ${mobileShow ? "show" : ""}`} onClick={() => handleMobileShow()} >
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div>
            </nav>
    </div>
</header>
<main>
    <aside>
        <div className="container">
            <div className="sidebar-wrapper">
                <div className="logo-green">
                    <img src={ic_logo_green} width="179px" height="35px" alt=""/>
                </div>
                <span className="side-wrapper t-white t-16-24 t-600 side-active">Your Stacked  Vaults</span>
                <span className="side-wrapper t-white t-16-24 t-600">All Vaults</span>
                <span className="side-wrapper t-white t-16-24 t-600">Normal Vaults</span>
                <span className="side-wrapper t-white t-16-24 t-600">Burning Vaults</span>
            </div>
        </div>
    </aside>
    <section className="home-sec-1">
        <div className="container c-home-sec-1">
            <div className="logo-green-wrapper d-none">
                <img src={ic_logo_green} alt=""/>
            </div>
            <div className="glow-wrapper">
                <div className="main-content-header">
                    <span className="t-white t-600 t-36-54">Vaults</span>
                    <form action="" className="search-form-gemvault">
                        <input type="search" name="search" id="search" className="search-input" placeholder="Search by name, symbol, or address"/>
                        <button className="cursor search-button">
                            <img src={ic_search} width="24px" height="24px" alt="ic-search"/>
                        </button>
                    </form>
                    <button className="cursor btn-green t-green t-500 t-16-24 cursor">Compound All</button>
                </div>
                <div className="main-content-body">
                    <div className="main-content-title">
                        <div className="txt-wrapper-1">
                            <span className="main-content-tittitle-coin le-txt t-14-21 t-grey">Instrument</span>
                        </div>
                        <div className="txt-wrapper-2">
                            <span className="main-content-title-txt t-14-21 t-grey">TVL</span>
                        </div>
                        <div className="txt-wrapper-3">
                            <span className="main-content-title-txt t-14-21 t-grey">Daily</span>
                        </div>
                        <div className="txt-wrapper-4">
                            <span className="main-content-title-txt t-14-21 t-grey">APY</span>
                        </div>
                    </div>
                    <div className={`accordion ${panelShow ? "show" : ""}`} onClick={() => handlePanelShow()}>
                        <div className="ic-acc-wrapper">
                            <div className="ic-front"><img src={ic_bnb} alt=""/></div>
                            <div className="ic-behind"><img src={ic_dgm} alt=""/></div>
                            <div className="title-coin-wrapper d-flex jcc fdc">
                                <span className="title-coin t-grey t-12-18 d-none">Instrument</span>
                                <span className="t-16-24 t-white">BNB - DGM</span>
                            </div>
                        </div>
                        <div className="tvl-value">
                            <span className="tvl-value-1 t-16-24 t-white t-600">$9,4458,023</span>
                            <span className="tvl-value-2 t-14-21 t-white">6,857,284 Market Staked</span>
                        </div>
                        <span className="daily-value-txt t-white t-16-24">0.22%</span>
                        <div className="apy-value">
                            <span className="t-white t-16-24">155%</span>
                            <img src={ic_help} alt=""/>
                        </div>
                    </div>
                    <div className={`panel ${panelShow ? "show" : ""}`}>
                        <hr/>
                        <div className="panel-btn-wrapper">
                        <div className="tvl-mobile-wrapper d-none">
                                <span className="t-12-18 t-grey">TVL</span>
                                <span className="t-16-24 t-white t-600 tvl-title">$9,4458,023</span>
                                <span className="t-14-21 t-white">6,857,284 Market Staked</span>
                            </div>
                            <div className="daily-apy-wrapper d-none">
                                <div className="daily-mobile-wrapper">
                                    <span className="t-12-18 t-grey">Daily</span>
                                    <span className="t-14-21 t-white">0.22%</span>
                                </div>
                                <div className="apy-mobile-wrapper">
                                    <span className="t-12-18 t-grey">APY</span>
                                    <div className="apy-ic-mobile">
                                        <span className="t-14-21 t-white">155%</span>
                                        <img src={ic_help_white} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <button className="cursor btn-solid-green t-white t-16-24">Approve</button>
                            <button className="cursor t-green btn-green-panel t-16-24">Deposit</button>
                            <button className="cursor t-green btn-transparent-panel t-16-24">Withdraw</button>
                        </div>
                    </div>
                    <div className={`accordion ${panelShow2nd ? "show" : ""}`} onClick={() => handlePanelShow2nd()}>
                        <div className="ic-acc-wrapper">
                            <div className="ic-front"><img src={ic_bnb} alt="ic-bnb"/></div>
                            <div className="ic-behind"><img src={ic_dgm} alt="ic-dgm"/></div>
                            <div className="title-coin-wrapper d-flex jcc fdc">
                                <span className="title-coin t-grey t-12-18 d-none">Instrument</span>
                                <span className="t-16-24 t-white">BNB - DGM</span>
                            </div>
                        </div>
                        <div className="tvl-value">
                            <span className="tvl-value-1 t-16-24 t-white t-600">$9,4458,023</span>
                            <span className="tvl-value-2 t-14-21 t-white">6,857,284 Market Staked</span>
                        </div>
                        <span className="daily-value-txt t-white t-16-24">0.22%</span>
                        <div className="apy-value">
                            <span className="t-white t-16-24">155%</span>
                            <img src={ic_help} alt=""/>
                        </div>
                    </div>
                    <div className={`panel ${panelShow2nd ? "show" : ""}`}>
                        <hr/>
                        <div className="panel-btn-wrapper">
                        <div className="tvl-mobile-wrapper d-none">
                                <span className="t-12-18 t-grey">TVL</span>
                                <span className="t-16-24 t-white t-600 tvl-title">$9,4458,023</span>
                                <span className="t-14-21 t-white">6,857,284 Market Staked</span>
                            </div>
                            <div className="daily-apy-wrapper d-none">
                                <div className="daily-mobile-wrapper">
                                    <span className="t-12-18 t-grey">Daily</span>
                                    <span className="t-14-21 t-white">0.22%</span>
                                </div>
                                <div className="apy-mobile-wrapper">
                                    <span className="t-12-18 t-grey">APY</span>
                                    <div className="apy-ic-mobile">
                                        <span className="t-14-21 t-white">155%</span>
                                        <img src={ic_help_white} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <button className="cursor btn-solid-green t-white t-16-24">Approve</button>
                            <button className="cursor t-green btn-green-panel t-16-24">Deposit</button>
                            <button className="cursor t-green btn-transparent-panel t-16-24">Withdraw</button>
                        </div>
                    </div>
                    <div className={`accordion ${panelShow3nd ? "show" : ""}`} onClick={() => handlePanelShow3nd()}>
                        <div className="ic-acc-wrapper">
                            <div className="ic-front"><img src="{ic_bnb}" alt=""/></div>
                            <div className="ic-behind"><img src={ic_dgm} alt=""/></div>
                            <div className="title-coin-wrapper d-flex jcc fdc">
                                <span className="title-coin t-grey t-12-18 d-none">Instrument</span>
                                <span className="t-16-24 t-white">BNB - DGM</span>
                            </div>
                        </div>
                        <div className="tvl-value">
                            <span className="tvl-value-1 t-16-24 t-white t-600">$9,4458,023</span>
                            <span className="tvl-value-2 t-14-21 t-white">6,857,284 Market Staked</span>
                        </div>
                        <span className="daily-value-txt t-white t-16-24">0.22%</span>
                        <div className="apy-value">
                            <span className="t-white t-16-24">155%</span>
                            <img src={ic_help} alt=""/>
                        </div>
                    </div>
                    <div className={`panel ${panelShow3nd ? "show" : ""}`}>
                        <hr/>
                        <div className="panel-btn-wrapper">
                        <div className="tvl-mobile-wrapper d-none">
                                <span className="t-12-18 t-grey">TVL</span>
                                <span className="t-16-24 t-white t-600 tvl-title">$9,4458,023</span>
                                <span className="t-14-21 t-white">6,857,284 Market Staked</span>
                            </div>
                            <div className="daily-apy-wrapper d-none">
                                <div className="daily-mobile-wrapper">
                                    <span className="t-12-18 t-grey">Daily</span>
                                    <span className="t-14-21 t-white">0.22%</span>
                                </div>
                                <div className="apy-mobile-wrapper">
                                    <span className="t-12-18 t-grey">APY</span>
                                    <div className="apy-ic-mobile">
                                        <span className="t-14-21 t-white">155%</span>
                                        <img src={ic_help_white} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <button className="cursor btn-solid-green t-white t-16-24">Approve</button>
                            <button className="cursor t-green btn-green-panel t-16-24">Deposit</button>
                            <button className="cursor t-green btn-transparent-panel t-16-24">Withdraw</button>
                        </div>
                    </div>
                    <div className={`accordion ${panelShow4nd ? "show" : ""}`} onClick={() => handlePanelShow4nd()}>
                        <div className="ic-acc-wrapper">
                            <div className="ic-front"><img src={ic_bnb} alt=""/></div>
                            <div className="ic-behind"><img src={ic_dgm} alt=""/></div>
                            <div className="title-coin-wrapper d-flex jcc fdc">
                                <span className="title-coin t-grey t-12-18 d-none">Instrument</span>
                                <span className="t-16-24 t-white">BNB - DGM</span>
                            </div>
                        </div>
                        <div className="tvl-value">
                            <span className="tvl-value-1 t-16-24 t-white t-600">$9,4458,023</span>
                            <span className="tvl-value-2 t-14-21 t-white">6,857,284 Market Staked</span>
                        </div>
                        <span className="daily-value-txt t-white t-16-24">0.22%</span>
                        <div className="apy-value">
                            <span className="t-white t-16-24">155%</span>
                            <img src={ic_help} alt=""/>
                        </div>
                    </div>
                    <div className={`panel ${panelShow4nd ? "show" : ""}`}>
                        <hr/>
                        <div className="panel-btn-wrapper">
                        <div className="tvl-mobile-wrapper d-none">
                                <span className="t-12-18 t-grey">TVL</span>
                                <span className="t-16-24 t-white t-600 tvl-title">$9,4458,023</span>
                                <span className="t-14-21 t-white">6,857,284 Market Staked</span>
                            </div>
                            <div className="daily-apy-wrapper d-none">
                                <div className="daily-mobile-wrapper">
                                    <span className="t-12-18 t-grey">Daily</span>
                                    <span className="t-14-21 t-white">0.22%</span>
                                </div>
                                <div className="apy-mobile-wrapper">
                                    <span className="t-12-18 t-grey">APY</span>
                                    <div className="apy-ic-mobile">
                                        <span className="t-14-21 t-white">155%</span>
                                        <img src={ic_help_white} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <button className="cursor btn-solid-green t-white t-16-24">Approve</button>
                            <button className="cursor t-green btn-green-panel t-16-24">Deposit</button>
                            <button className="cursor t-green btn-transparent-panel t-16-24">Withdraw</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</main>
    </>
    )
}

export default Gemvault;
