import $ from 'jquery'

import logo from '../assets/asset_dApp/degem-left-logo.svg'
import exchange_img from "../assets/asset_exchange_landing/exchange-img.png"

import { NavLink } from "react-router-dom";

// navbarnfooterglobal
import Nav from '../components/comp/navbar.js'
import Footer from '../components/comp/footer.js'



function Exchange_landing() {
    require('../css/exchange_landing.css')
    require('../components/style/global.css')
    
    function toggleDrop(t){
        $(".nav-menu > div > div:last-child").slideUp() 
        $(".nav-menu > div > div:first-child").removeClass('active')
        
        $(t.currentTarget).toggleClass('active')
        $($(t.currentTarget).next()).toggleClass('active')
    
    }

    return (
        <>
        <Nav img={logo} color="#0E93F5"/>
        <div class="section ex-landing-sec1">
            <div class="container">

                <div class="ex-main-box flex-c h-center">
                    <img src={exchange_img}/>
                    <p class="t-24-36 t-center">
                        The degem exchange provides traders the very best user experience, top tier quality, and relentless innovation.
                    </p>
                    <NavLink to='/exchange' class="mainbox-btn t-cursor t-18-27" exact>
                        Trade Now
                    </NavLink>
                </div>
                
            </div>
            <div class="container flex-r h-right">
                
                <div class="feature-box">
                    <h2 class="t-36-54">
                        Features
                    </h2>
                    <div class="t-18-37">
                        Routed to PCS liquidity pools
                    </div>
                    <div class="t-18-37">
                        Smart dashboard with contract analysis
                    </div>
                    <div class="t-18-37">
                        Custom UI
                    </div>
                    <div class="t-18-37">
                        Charts
                    </div>
                    <div class="t-18-37">
                        Auto slippage
                    </div>
                    <div class="t-18-37">
                        Limit orders/stop losses
                    </div>
                    <div class="t-18-37">
                        IDO launchpad
                    </div>
                    <div class="t-18-37">
                        gem cave
                    </div>
                </div>
            </div>
        </div>
        <Footer color="#0E93F5"/>
        </>
    );
}

export default Exchange_landing;
