import React from 'react';
// navbar global
import Navbar from '../components/comp/navbar.js'
// asset 

import icCentersvg from '../assets/asset_homepage/ic-center.png';
import icTele from '../assets/asset_homepage/ic-telegram.svg';
import icTwt from '../assets/asset_homepage/ic-twitter.svg';
import icYt from '../assets/asset_homepage/ic-youtube.svg';

import icLogoPng from '../assets/asset_homepage/ic-logo.png';
import icburger from '../assets/asset_homepage/burger.svg';
import close from '../assets/asset_homepage/close-img.svg'





const Homepage = () => {
    require('../css/homepage_new.css');
    return (
        <>
        <Navbar 
        img={icLogoPng}
        />
        {/* <div className="t-navbar">
        <div className="container">
            <a href="#" className="cursor">
                <img src={icLogoPng} alt="ic-logo"/>
            </a>
            <button className="cursor t-nav-open" onclick="navMenu(this)">
                <img src={icburger} alt="ic-burger" />
            </button>
            <div className="nav-menu">
                <button className="cursor t-nav-close" onclick="navMenu(this)">
                    <img src={close} alt="ic-burger" />
                </button>

                <div className="nav-link-wrap">
                    <div className="nav-link-head t-cursor" onclick="collapse(this)">
                        Ecosystem
                    </div>
                    <div className="nav-link-body">
                        <a href="./Exchange_landing.js" target="_blank" className="t-cursor">
                        Exchange
                        </a>
                        <a href="./Treasury.js" target="_blank" className="t-cursor">
                        Treasury
                        </a>
                        <a href="#" className="t-cursor">
                        Refinery
                        </a>
                        <a href="https://ido.degem.tech" target="" className="t-cursor">
                        Launchpad
                        </a>
                        <a href="./Gemcave.js" target="_blank" className="t-cursor">
                        Gem cave 
                        </a>
                    </div>
                </div>
                <div className="nav-link-wrap">
                    <div className="nav-link-head t-cursor" onclick="collapse(this)">
                        Documentation
                    </div>
                    <div className="nav-link-body">
                        <a className="t-cursor">
                        Whitepaper
                        </a>
                        <a className="t-cursor">
                        One pager 
                        </a>
                        <a className="t-cursor">
                        Audit Report 
                        </a>
                    </div>
                </div>
                <a className="nav-link-wrap t-cursor">
                    Roadmap
                </a>
                <a className="nav-link-wrap special t-cursor">
                    Contact Us
                </a>
            </div>
        </div>
    </div> */}
    <div className="section homepage-landing-sec1">
        <div className="container">
            <div className="home-main-box flex-c h-center">
                <img src={icCentersvg} alt="ic-center" />
                <p className="t-18-27 t-center">
                  degem is a decentralized products and services platform that empowers the investor, simplifies the trading experience, and gives YOU the edge needed to be successful
                </p>
                <div className="btn-wrap flex-r">
                  <a href="https://twitter.com/degemofficial?s=09" target="_blank" className="t-14-21 flex-r v-center cursor">
                    <img src={icTwt} alt="ic-twitter"/> Twitter
                  </a>
                  <a href="https://t.me/degemofficial" target="_blank" className="t-14-21 flex-r v-center cursor btn-tele">
                    <img src={icTele} alt="ic-telegram"/> Telegram
                  </a>
                  <a href="#" className="t-14-21 flex-r v-center cursor">
                    <img src={icYt} alt="ic-youtube"/> Youtube
                  </a>
                </div>
            </div>
        </div>
    </div>
        </>
    );
};

export default Homepage;
