import React, { Component } from 'react';
import $ from 'jquery';
import { NavLink } from "react-router-dom";

// asset css
import '../style/navbar.css';
import '../style/global.css';


// assets image
import burger from '../../assets/asset_global/burger.svg'
import logo from '../../assets/asset_global/logo launchpad degem.png'
import closeImg from '../../assets/asset_global/close-img.svg'

class navbar extends Component {
    constructor(props) {
        super(props);
    }

    collapse1 = (e) =>{
            $($('.nav-menu').parent()).toggleClass('active')
            $($('#1').next()).slideToggle()
        }
    collapse2 = (e) =>{
            $($('.nav-menu').parent()).toggleClass('active')
            $($('#2').next()).slideToggle()
        }
    navBar = () =>{
            $('.nav-menu').toggleClass('active')
        }
    
    componentDidMount = ()=>{
        console.log(this.props.color)
    }

    render(){
        return (
            <>
            <div class="t-navbar">
                <div class="container">

                    <NavLink to='/' class="cursor" exact>
                        <img src={this.props.img}/>
                    </NavLink>
                

                    <button class="cursor t-nav-open" onClick={this.navBar}>
                        <img src={burger} />
                    </button>

                    <div class="nav-menu">
                        <button class="cursor t-nav-close" onClick={this.navBar} >
                            <img src={closeImg} />
                        </button>

                        <div class="nav-link-wrap ">
                            <div class="nav-link-head t-cursor" id="1" onClick={this.collapse1}>
                                Ecosystem
                            </div>
                            <div class="nav-link-body" >

                                <NavLink to='/exchange_landing' class="t-cursor" exact>
                                    Exchange
                                </NavLink>
                                <NavLink to='/treasury' class="t-cursor" exact>
                                    Treasury
                                </NavLink>
                                <a class="t-cursor">
                                Refinery
                                </a>
                                <NavLink to='https://ido.degem.tech' class="t-cursor" exact>
                                    Launchpad
                                </NavLink>
                                <NavLink to='/gemcave' class="t-cursor" exact>
                                    Gemcave
                                </NavLink>
                                
                            </div>
                        </div>
                        <div class="nav-link-wrap ">
                            <div class="nav-link-head t-cursor" id="2" onClick={this.collapse2} >
                                Documentation
                            </div>
                            <div class="nav-link-body">
                                <a class="t-cursor">
                                Whitepaper
                                </a>
                                <a class="t-cursor">
                                One pager 
                                </a>
                                <a class="t-cursor">
                                Audit Report 
                                </a>
                            </div>
                        </div>
                        <a class="nav-link-wrap t-cursor">
                            Roadmap
                        </a>
                        <a class="nav-link-wrap special t-cursor btn-nav-glob" style={{background:this.props.color}}>
                            Contact Us
                        </a>
                    </div>
                </div>
            </div>
            </>
        );
      }
}



export default navbar;
