import React from 'react';
import { NavLink } from 'react-router-dom';


import '../css/treasury.css';
import '../components/style/global.css';
// asset 
import logotresury from '../assets/asset_treasury/treasury.png'


import diagram1 from '../assets/asset_treasury/diagram-1.png';
import diagram2 from '../assets/asset_treasury/diagram-2.png';

import ornamenttres from '../assets/asset_treasury/ornamen-bg-treasury.png';
import logo from '../assets/asset_dApp/degem-left-logo.svg'

// navbarglobaltry

import Nav from '../components/comp/navbar.js'
import Footer from '../components/comp/footer.js'

const Treasury = () => {

    return (
        <>

{/* navbar-here */}
<Nav img={logo} color="#E5C10E"/>

    <div class="section t-sec1">
        <div class="container">
  {/* from palin html  */}
        <div class="tres-main-box flex-c h-center">
                <img src={logotresury}/>
                <p class="t-18-27 t-center">
                    Our treasury receives revenue via trading fees from the degem exchange.  50% of our net revenue is distributed to degem tokenholders.
                </p>

              <NavLink to="/dapp"  class="cursor t-18-27" exact>
                <a >
                    Rewards Dapp
                </a> 
            </NavLink>
            </div>
        </div>
  </div>


<div class="section tres-margin-top">
  <div class="container">
            <div class="tier">
              <div class="img-tier">
                <img class="diagram" src={diagram1} alt="diagram-1"/>
              </div>
              <div class="tittle-tier">
                <div class="diag-1 right">
                  <h1 class="t-tres-tittle">
                    degem exchange trading fee breakdown
                  </h1>
                  <p class="t-tres-p">
                    Trading fee is 1% from the total transaction
                  </p>
                </div>
              </div>
            </div>

  </div>

</div>

<div class="section margin-btm-100">
  <div class="container">

            <div class="tier mobile-ver">
              <div class="tittle-tier">
                <div class="diag-1 left">
                  <h1 class="t-tres-tittle">
                    degem treasury net revenue breakdown
                  </h1>
                 
                </div>
              </div>
              <div class="img-tier">
                <img class="diagram" src={diagram2} alt="diagram-2"/>
              </div>
            </div>
  </div>
</div>
            {/* <!-- sec-tier-fromplain--> */}


           








<Footer color="#E5C10E"/>
        </>
    );
};

export default Treasury;