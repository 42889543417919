import React from 'react';

import logo from '../assets/asset_dApp/degem-left-logo.svg'
import logo_dapp from '../assets/asset_dApp/treasury-logo.svg'
import logout_ico from '../assets/asset_dApp/logout-ico.svg'

import trans_ico from "../assets/asset_dApp/transaction-ico.svg"
import liquid_ico from "../assets/asset_dApp/liquid-ico.svg"
import binance_ico from "../assets/asset_dApp/binance-logo.svg"
import dgm_ico from "../assets/asset_dApp/dgm-ico.svg"

import copy_ico from "../assets/asset_dApp/copy-ico.svg"



function Dapp() {
    require('../css/dApp.css')
    require('../css/global.css')

    return (
        <>
        <div class="navbar">
                <div class="container flex-r just-space v-center">
                    <a class="cursor">
                        <img src={logo}/>
                    </a>
                    <a class="logout-btn cursor t-500 t-16-24 t-center">
                        <span class="m-hide">Log Out</span>
                        <img class="m-show" src={logout_ico}/>
                    </a>
                </div>
            </div>

            <div class="section dapp-main-content">
                <div class="container flex-r">

                    <img class="m-show m-logo" src={logo_dapp}/>

                    <div class="left-block">
                        <div class="neon-box left-itm flex-c v-center">
                            <img src={trans_ico} />
                            <span class="t-18-27 block">
                                Max transaction amount
                            </span>
                            <div class="t-600 t-28-42">
                                10.000.00
                                <span class="t-400 t-16-24">
                                    DGM
                                </span>
                            </div>
                        </div>
                        <div class="neon-box left-itm flex-c v-center">
                            <img src={liquid_ico} />
                            <span class="t-18-27 block">
                                Total Liquidity Pool
                            </span>
                            <div class="t-600 t-28-42">
                                $478,433.221
                            </div>
                        </div>
                        <div class="neon-box left-itm flex-c v-center">
                            <img src={binance_ico} />
                            <span class="t-18-27 block">
                                Total BNB in Liquidity Pool
                            </span>
                            <div class="t-600 t-28-42">
                                832.35
                                <span class="t-400 t-16-24">
                                    BNB
                                </span>
                            </div>
                        </div>
                        <div class="neon-box left-itm flex-c v-center">
                            <img src={dgm_ico} />
                            <span class="t-18-27 block">
                                Current $DGM Price
                            </span>
                            <div class="t-600 t-28-42">
                                $0.00326
                            </div>
                        </div>


                    </div>

                    <div class="right-block flex-c">

                        <img class="m-hide" src={logo_dapp}/>

                        <div class="neon-box address-block flex-r">
                            <div class="flex-c">
                                <span class="t-16-24">Your Address</span>
                                <div class="flex-r v-center">
                                    <span class="t-600 t-16-24">
                                        3FZbgi29cpjq2GjdwV8eyHuJJnkLtktZc5
                                    </span>
                                    <img class="cursor" onClick={() => {navigator.clipboard.writeText('3FZbgi29cpjq2GjdwV8eyHuJJnkLtktZc5')}} src={copy_ico}/>
                                </div>
                            </div>
                            <button class="cursor btn-1 t-18-27">
                                Buy DGM
                            </button>
                        </div>


                        <div class="neon-box reward-block flex-r">
                            <img src={binance_ico}/>
                            <div class="flex-c h-left">
                                <span class="t-24-36">
                                    My Reward 
                                </span>
                                <div class="t-40-60 t-600">
                                    0.000004 <span class="t-16-24 t-400">BNB</span>
                                </div>
                                <button class="cursor btn-1 t-18-27">
                                    Claim Reward
                                </button>
                            </div>
                        </div>


                        <div class="neon-box flex-c reward-pool-block">
                            <span class="t-18-27">
                                Reward Pool
                            </span>
                            <span class="t-28-42 t-600">
                                6.07 BNB
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dapp;
