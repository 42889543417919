import React, { useEffect } from 'react';
import { MiniChart, AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { NavLink } from "react-router-dom";

import logo from "../assets/asset_exchange/main-logo.svg"

import dashboard_ico from "../assets/asset_exchange/dashboard-ico.svg"
import launchpad_ico from "../assets/asset_exchange/launchpad-ico.svg"
import gemcave_ico from "../assets/asset_exchange/gemcave-ico.svg"
import refinery_ico from "../assets/asset_exchange/refinery-ico.svg"
import treasury_ico from "../assets/asset_exchange/treasury-ico.svg"
import scanner_ico from "../assets/asset_exchange/scanner-ico.svg"

import tele_ico from "../assets/asset_exchange/social1-ico.svg"
import twitter_ico from "../assets/asset_exchange/social2-ico.svg"
import web_ico from "../assets/asset_exchange/social3-ico.svg"
import setting_ico from "../assets/asset_exchange/setting-ico.svg"

import search_ico from "../assets/asset_exchange/search-ico.svg"
import exchange_arrow from "../assets/asset_exchange/gg_arrows-exchange.svg"
import btm_banner from "../assets/asset_exchange/btm-banner.png"




function Exchange() {
    require('../css/exchange.css')
    return (
        <>
            <div class="side-nav flex-c h-center">
                    
                    <a class="logo block">
                        <img class="big" src={logo}/>
                    </a>
            
                    <div class="side-menu-wrap flex-c h-left">
                        <NavLink to='' class="cursor flex-r v-center menu-1" exact>
                            <img src={dashboard_ico} />
                        </NavLink>
                        <NavLink to='/launchpad' class="cursor flex-r v-center menu-2" exact>
                            <img src={launchpad_ico} />
                        </NavLink>
                        <NavLink to='/gemcave' class="cursor flex-r v-center menu-3" exact>
                            <img src={gemcave_ico} />
                        </NavLink>
                        <NavLink to='' class="cursor flex-r v-center menu-4" exact>
                            <img src={refinery_ico} />
                        </NavLink>
                        <NavLink to='/treasury' class="cursor flex-r v-center menu-5" exact>
                            <img src={treasury_ico} />
                        </NavLink>
                        <NavLink to='' class="cursor flex-r v-center menu-6" exact>
                            <img src={scanner_ico} />
                        </NavLink>
                    </div>
            
                    <div class="side-menu-btm flex-c h-left">
                        <a class="cursor flex-r v-center" href="https://t.me/degemofficial" target="_blank">
                            <img src={tele_ico} /> 
                            <span>Telegram</span>
                        </a>
                        <a class="cursor flex-r v-center" href="https://twitter.com/degemofficial?s=09" target="_blank">
                            <img src={twitter_ico} /> 
                            <span>Twitter</span>
                        </a>
                        <a class="cursor flex-r v-center" href="https://Degem.io" target="_blank">
                            <img src={web_ico} /> 
                            <span>Website</span>
                        </a>
                        <a class="cursor flex-r v-center">
                            <img src={setting_ico} /> 
                            <span>Setting</span>
                        </a>
                    </div>
                </div>
            
            <div class="section">
                    <div class="container flex-r">
                        <div class="main-content">
            
                            <div class="banner-top-wrapper margin-btm-35 flex-r h-center">
                                <img src="" />
                                <img src="" />
                            </div>
            
                            <div class="top-toolbar flex-r v-center just-space margin-btm-51">    
                                <div class="top-search-wrap flex-r v-center just-space">
                                    <div class="top-searchbox flex-r v-center">
                                        <img src={search_ico}/>
                                        <input type="text" placeholder="Search.." />
                                    </div>
                                    <button class="t-500 t-14-14 cursor">
                                        Connect Wallet
                                    </button>
                                </div>
                            </div>
            
            
                            
                            <div class="live-chart-content margin-btm-32">
                                <div class="top-content flex-r just-space v-center margin-btm-24">
                                    <label class="t-700 t-20-24 d-none">
                                        Live Chart
                                    </label>
                                </div>
            
                                
                                <div class="top-coin margin-btm-48">
                                    <div class="top-block flex-c">
                                        <div class="flex-r v-center just-space">
                                            <div class="flex-r v-center t-600 t-18-21 coin-title">
                                                <img />
                                                Bitcoin
                                            </div>
                                            <span class="t-600 t-18-21 coin-desc">
                                                BTC
                                            </span>
                                        </div>
                                        <div class="tradingview-widget-container">
                                            <MiniChart 
                                                colorTheme="dark"
                                                symbol="COINBASE:BTCUSD"
                                                width="100%"
                                                height="100%"
                                                dateRange="1D"
                                                trendLineColor="rgba(41, 98, 255, 1)"
                                                underLineColor="rgba(41, 98, 255, 0.3)"
                                                underLineBottomColor="rgba(41, 98, 255, 0)"
                                                isTransparent={false}
                                                locale="en"
                                            ></MiniChart>
                                        </div>
                                    </div>
                                    <div class="top-block flex-c">
                                        <div class="flex-r v-center just-space">
                                            <div class="flex-r v-center t-600 t-18-21 coin-title">
                                                <img />
                                                Ethereum
                                            </div>
                                            <span class="t-600 t-18-21 coin-desc">
                                                ETH
                                            </span>
                                        </div>
                                        <div class="tradingview-widget-container">
                                            <MiniChart 
                                                colorTheme="dark"
                                                symbol="COINBASE:ETHUSD"
                                                width="100%"
                                                height="100%"
                                                dateRange="1D"
                                                trendLineColor="rgba(41, 98, 255, 1)"
                                                underLineColor="rgba(41, 98, 255, 0.3)"
                                                underLineBottomColor="rgba(41, 98, 255, 0)"
                                                isTransparent={false}
                                                locale="en"
                                            ></MiniChart>
                                        </div>
                                    </div>
                                    <div class="top-block flex-c">
                                        <div class="flex-r v-center just-space">
                                            <div class="flex-r v-center t-600 t-18-21 coin-title">
                                                <img />
                                                Theter
                                            </div>
                                            <span class="t-600 t-18-21 coin-desc">
                                                USDT
                                            </span>
                                        </div>
                                        <div class="tradingview-widget-container">
                                            <MiniChart 
                                                colorTheme="dark"
                                                symbol="COINBASE:USDTUSD"
                                                width="100%"
                                                height="100%"
                                                dateRange="1D"
                                                trendLineColor="rgba(41, 98, 255, 1)"
                                                underLineColor="rgba(41, 98, 255, 0.3)"
                                                underLineBottomColor="rgba(41, 98, 255, 0)"
                                                isTransparent={false}
                                                locale="en"
                                            ></MiniChart>
                                        </div>
                                    </div>
                                </div>
            
            
            
                                <div class="inner-content flex-c">
                                    <div class="ticker-coin flex-r v-center margin-btm-24">
                                        <div class="flex-r just-space">
                                            <div class="flex-r">
                                                <img />
                                                <div class="flex-c">
                                                    <span class="t-600 t-16-19 coin-name">
                                                        DOGE/BNB
                                                    </span>
                                                    <span class="t-500 t-12-14 coin-value">
                                                        0.216440
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="flex-c">
                                                <span class="t-600 t-16-19 coin-name">
                                                    Total Supply
                                                </span>
                                                <span class="t-500 t-12-14 coin-value">
                                                    100,000,000,000,000,000
                                                </span>
                                            </div>
                                            <div class="flex-c">
                                                <span class="t-600 t-16-19 coin-name">
                                                    Market Cap
                                                </span>
                                                <span class="t-500 t-12-14 coin-value">
                                                    $432,972,736
                                                </span>
                                            </div>
                                            <div class="flex-c">
                                                <span class="t-600 t-16-19 coin-name">
                                                    Liquidity
                                                </span>
                                                <span class="t-500 t-12-14 coin-value">
                                                    $11,023,312
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chart-block">
                                        <div class="chart-content">
                                            <AdvancedRealTimeChart 
                                                autosize
                                                symbol="FX:BTCUSD"
                                                interval="5"
                                                timezone="Etc/UTC"
                                                theme='dark'
                                                style="1"
                                                locale="en"
                                                toolbar_bg="#f1f3f6"
                                                hide_side_toolbar={false}
                                                allow_symbol_change={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
            
            
                            <div class="swap-token-content margin-btm-32">
                                
            
            
                                
                                <div class="inner-content flex-r v-btm just-space">
                                    <div class="swap-inpt-wrap">
                                        <div class="label block margin-btm-16 flex-r v-btm just-space">
                                            <span class="t-500 t-18-21">From</span>
                                        </div>
                                        <div class="swap-inpt-block flex-r v-center">
                                            <input type="number" placeholder="Ex. 12,000..."/>
                                            <select>
                                                <option>
                                                    BTC
                                                </option>
                                                <option>
                                                    ETH
                                                </option>
                                                <option>
                                                    USDT
                                                </option>
                                            </select>
                                        </div>
                                    </div>
            
                                    <img src={exchange_arrow}/>
            
                                    <div class="swap-inpt-wrap">
                                        <div class="label block margin-btm-16 flex-r v-btm just-space">
                                            <span class="t-500 t-18-21">To</span>
                                            <button class="hide-m cursor">
                                                0,5%
                                            </button>
                                        </div>
                                        <div class="swap-inpt-block flex-r v-center">
                                            <input type="number" placeholder="Ex. 12,000..."/>
                                            <select>
                                                <option>
                                                    BTC
                                                </option>
                                                <option>
                                                    ETH
                                                </option>
                                                <option>
                                                    USDT
                                                </option>
                                            </select>
                                        </div>
                                    </div>
            
                                    <div class="swap-btn-wrap flex-c"> 
                                        <div class="flex-r">
                                            <button class="cursor t-400 t-14-16 margin-btm-16">
                                                Auto Slippage
                                            </button>
                                        </div>
                                        <button class="cursor btn-gradient t-500 t-14-14">
                                            Swap
                                        </button>
                                    </div>
                                </div>
                            </div>
            
            
                            <div class="order-book-content margin-btm-80">
                                
            
                                
                                <div class="inner-content">
                                    <div class="order-table-head flex-r just-space">
                                        <div class="flex-r v-center h-center">
                                            <div class="t-16-19">
                                                TYPE
                                            </div>
                                        </div>
                                        <div class="flex-r v-center h-center">
                                            <div class="t-16-19">
                                                PRICE
                                            </div>
                                        </div>
                                        <div class="flex-r v-center h-center">
                                            <div class="t-16-19">
                                                TOKEN
                                            </div>
                                        </div>
                                        <div class="hide-m flex-r v-center h-center">
                                            <div class="t-16-19">
                                                Price/Token
                                            </div>
                                        </div>
                                        <div class="flex-r v-center h-center">
                                            <div class="t-16-19">
                                                TIME
                                            </div>
                                        </div>
                                        <div class="hide-m flex-r v-center h-center">
                                            <div class="t-16-19">
                                                TX
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="order-itm-wrap">
                                        <div class="order-table-itm green-label flex-r just-space">
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 ">
                                                    Buy
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    $39.48
                                                    <br/>
                                                    0.94862 BNB
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    289,000.125
                                                    <br/>
                                                    Doge
                                                </div>
                                            </div>
                                            <div class="hide-m flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    $0.1348
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    18:34:28
                                                </div>
                                            </div>
                                            <div class="hide-m flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    0x3896
                                                </div>
                                            </div>
                                        </div>
                                        <div class="order-table-itm green-label flex-r just-space">
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 ">
                                                    Buy
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    $39.48
                                                    <br/>
                                                    0.94862 BNB
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    289,000.125
                                                    <br/>
                                                    Doge
                                                </div>
                                            </div>
                                            <div class="hide-m flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    $0.1348
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    18:34:28
                                                </div>
                                            </div>
                                            <div class="hide-m flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    0x3896
                                                </div>
                                            </div>
                                        </div>
                                        <div class="order-table-itm green-label flex-r just-space">
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 ">
                                                    Buy
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    $39.48
                                                    <br/>
                                                    0.94862 BNB
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    289,000.125
                                                    <br/>
                                                    Doge
                                                </div>
                                            </div>
                                            <div class="hide-m flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    $0.1348
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    18:34:28
                                                </div>
                                            </div>
                                            <div class="hide-m flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    0x3896
                                                </div>
                                            </div>
                                        </div>
                                        <div class="order-table-itm green-label flex-r just-space">
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 ">
                                                    Buy
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    $39.48
                                                    <br/>
                                                    0.94862 BNB
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    289,000.125
                                                    <br/>
                                                    Doge
                                                </div>
                                            </div>
                                            <div class="hide-m flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    $0.1348
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    18:34:28
                                                </div>
                                            </div>
                                            <div class="hide-m flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    0x3896
                                                </div>
                                            </div>
                                        </div>
                                        <div class="order-table-itm green-label flex-r just-space">
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 ">
                                                    Buy
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    $39.48
                                                    <br/>
                                                    0.94862 BNB
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    289,000.125
                                                    <br/>
                                                    Doge
                                                </div>
                                            </div>
                                            <div class="hide-m flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    $0.1348
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    18:34:28
                                                </div>
                                            </div>
                                            <div class="hide-m flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    0x3896
                                                </div>
                                            </div>
                                        </div>
                                        <div class="order-table-itm green-label flex-r just-space">
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 ">
                                                    Buy
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    $39.48
                                                    <br/>
                                                    0.94862 BNB
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    289,000.125
                                                    <br/>
                                                    Doge
                                                </div>
                                            </div>
                                            <div class="hide-m flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    $0.1348
                                                </div>
                                            </div>
                                            <div class="flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    18:34:28
                                                </div>
                                            </div>
                                            <div class="hide-m flex-r v-center h-center">
                                                <div class="t-16-19 t-right">
                                                    0x3896
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            
            
                            
                            <div class="btm-banner flex-r h-center">
                                <img src={btm_banner}/>
                            </div>
            
                        </div>
                    </div>
                </div>
        </>
    );
}

export default Exchange;
