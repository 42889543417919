import $ from 'jquery'
import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

import toggle_sidebar from "../assets/asset_ido/ic-toggle-sidebar.svg"
import logo_degem from "../assets/asset_ido/logo-degem.svg"
import toggle_light from "../assets/asset_ido/toggle-light-mode.png"
import ic_close from "../assets/asset_ido/ic-close.svg"
import ic_plus from "../assets/asset_ido/ic-plus.svg"

import home_ico from "../assets/asset_ido/Home.svg"
import star_ico from "../assets/asset_ido/Star.svg"
import chart_ico from "../assets/asset_ido/Chart.svg"
import doc_ico from "../assets/asset_ido/Document.svg"
import send_ico from "../assets/asset_ido/Send.svg"
import heart_ico from "../assets/asset_ido/Heart.svg"

import ic_dash from "../assets/asset_ido/ic-dashboard.svg"
import ic_launch from "../assets/asset_ido/ic-launchpad.svg"
import ic_gem from "../assets/asset_ido/ic-gemcave.svg"
import ic_refinery from "../assets/asset_ido/ic-refinery.svg"
import ic_treasure from "../assets/asset_ido/ic-teasury.svg"

import ic_search from "../assets/asset_ido/ic-search.svg"
import ic_drop from "../assets/asset_ido/ic-dropdown.svg"
import ic_onic from "../assets/asset_ido/ic-onic.png"

import arr_left from "../assets/asset_ido/arrow-left.svg"
import arr_right from "../assets/asset_ido/arrow-right.svg"

import twt_ico from "../assets/asset_ido/ic-twitter.svg"
import medium_ico from "../assets/asset_ido/ic-medium.svg"
import tele_ico from "../assets/asset_ido/ic-telegram.svg"
import git_ico from "../assets/asset_ido/ic-github.svg"


function removeSideM(){
    $('.sidebar-mobile-wrapper').removeClass('appear')
}

function toggleSide(){
        $(".sidebar-txt").toggleClass('appear')
        $('.sidebar-mobile-wrapper-ido').toggleClass('appear')
        $(".sidebar-wrapper-ido").addClass('appear')
        $(".create-txt-ori").toggleClass("appear")
}

function Ido() {
    require('../css/ido.css')
    
    $(document).ready(function() {
        $(window).resize(function(){
            if(window.innerWidth < 600) {
                $("body").addClass("mobile")
            }
            else {
                $("body").removeClass("mobile")
            }
        })
    })
    
    const [mobileShow, setMobileShow] = useState(false)
    const handleMobileShow = () => {
        setMobileShow(prev => !prev)
    }
    const [dropdownShow, setDropdownShow] = useState(false)
    const handleDropdownShow = () => {
        setDropdownShow(prev => !prev)
    }

    const [currentStatus, setCurrentStatus] = useState('status')
  
    const changeStatus = (newStatus) => {
        setCurrentStatus(newStatus)
    }
    const [currentFilter, setCurrentFilter] = useState('filter')
  
    const changeFilter = (newFilter) => {
        setCurrentFilter(newFilter)
    }
    return (
        <>
            <header className="header">
                <nav className="navbar">
                    <div className="logo-wrapper">
                        <img src={toggle_sidebar} className="toggle-sidebar" onClick={() => handleMobileShow()} width="24px" height="24px" alt="" />
                        <a href="#" class="nav-logo"><img src={logo_degem} alt="" /></a>
                    </div>
                    <ul className="nav-menu">
                        <div className="btn-nav-wrapper">
                            <img className="toggle" src={toggle_light} alt=""/>
                            <button className="btn-blockchain">Blockchain</button>
                            <button className="btn-connect">Connect</button>
                        </div>
                        
                    </ul>
                </nav>
            </header>
            <div className="aside">
                <div className={`sidebar-mobile-wrapper-ido ${mobileShow ? "appear" : ""}`}>
                    <div className="sidebar-header-mobile">
                        <a href="#" class="ic-close" onClick={() => toggleSide()}>
                            <img src={ic_close} alt="" />
                        </a>
                        <img src={logo_degem} alt="" />
                    </div>
                    <button className="btn-create-mobile">
                        <img src={ic_plus} alt="" />
                        <span className={`create-txt ${mobileShow ? "appear" : ""}`}>Create</span>
                    </button>
                    <div className="sidebar-wrapper-ido-mobile">
                        <NavLink className="sidebar-menu-wrapper-mobile sidebar-active" to="/exchange" target="_blank">
                            <img src={ic_dash} alt="" />
                            <span className={`sidebar-txt ${mobileShow ? "appear" : ""}`}>exchange</span>
                        </NavLink>
                        <NavLink className="sidebar-menu-wrapper-mobile" to="/launchpad" target="_blank">
                            <img src={ic_launch} alt="" />
                            <span href="" target="_blank" className={`sidebar-txt ${mobileShow ? "appear" : ""}`}>launchpad</span>
                        </NavLink>
                        <NavLink className="sidebar-menu-wrapper-mobile" to="/gemcave" target="_blank">
                            <img src={ic_gem} alt="" />
                            <span className={`sidebar-txt ${mobileShow ? "appear" : ""}`}>gem cave</span>
                        </NavLink>
                        <div className="sidebar-menu-wrapper-mobile">
                            <img src={ic_refinery} alt="" />
                            <span className={`sidebar-txt ${mobileShow ? "appear" : ""}`}>refinery</span>
                        </div>
                        <NavLink className="sidebar-menu-wrapper-mobile" to="/treasury" target="_blank">
                            <img src={ic_treasure} alt="" />
                            <span className={`sidebar-txt ${mobileShow ? "appear" : ""}`}>teasury</span>
                        </NavLink>
                    </div>
                    <div className="btn-nav-wrapper-mobile">
                        <img className="toggle-mobile" src={toggle_light} alt="" />
                        <button className="btn-blockchain">Blockchain</button>
                        <button className="btn-connect">Connect</button>
                    </div>
                </div>
                <div className="sidebar-wrapper-ido appear">
                    <button className="btn-create">
                        <img src={ic_plus} alt="ic_plus" />
                        <span href="" className={`create-txt-ori ${mobileShow ? "appear" : ""}`}>Create</span>
                    </button>
                    <NavLink className="sidebar-menu-wrapper sidebar-active" to="/exchange_landing" target="_blank">
                        <img src={ic_dash} alt="ic_exchange" />
                        <span className={`sidebar-txt ${mobileShow ? "appear" : ""}`}>exchange</span>
                    </NavLink>
                    <NavLink className="sidebar-menu-wrapper" to="/launchpad" target="_blank">
                        <img src={ic_launch} alt="ic_launchpad" />
                        <span className={`sidebar-txt ${mobileShow ? "appear" : ""}`}>launchpad</span>
                    </NavLink>
                    <NavLink className="sidebar-menu-wrapper" to="/gemcave" target="_blank">
                        <img src={ic_gem} alt="ic_gemcave" />
                        <span className={`sidebar-txt ${mobileShow ? "appear" : ""}`}>gem cave</span>
                    </NavLink>
                    <div className="sidebar-menu-wrapper">
                        <img src={ic_refinery} alt="ic_refinery" />
                        <span className={`sidebar-txt ${mobileShow ? "appear" : ""}`}>refinery</span>
                    </div>
                    <NavLink className="sidebar-menu-wrapper" to="/treasury" target="_blank">
                        <img src={ic_treasure} alt="ic_treasury" />
                        <span className={`sidebar-txt ${mobileShow ? "appear" : ""}`}>treasury</span>
                    </NavLink>
                </div>
            </div>
            <section className="ido-home-sec-1">
                <div className="container-ido c-ido-home-sec-1">
                    <div className="ido-home-sec-1-tilte-wrapper">
                    <span className="title-ido">Current IDO’s</span>
                    </div>
                    <div className="home-page-wrapper">
                        <a href="#" className="home-main-page">All IDO’s</a>
                    </div>
                    <div className="home-sorting-wrapper">
                        <form action="" className="home-search-form">
                            <button className="search-button">
                                <img src={ic_search} width="20px" height="20px" alt="ic-search"/>
                            </button>
                            <input type="search" name="search" id="search" className="search-input" placeholder="Enter token name or symbol" />
                        </form>
                        <div className="filter-wrapper">
                            <div className="dropdown-filter-wrapper">
                                <span className="dropdown-title">Filter by</span>
                                <form >
                                    <select 
                                        className="dropdown-filter-content"
                                        onChange={(event) => changeFilter(event.target.value)}
                                        value={currentFilter}
                                    >
                                        <option value="filter">All Filter</option>
                                        <option value="what">what</option>
                                        <option value="are">are</option>
                                        <option value="you">you</option>
                                        <option value="want">want</option>
                                    </select>
                                </form>
                                {/* <div className="dropdown-status" onClick={() => handleDropdownShow()} >
                                    <button className="btn-dropdown-filter">
                                    All Status
                                    <img src={ic_drop} alt="ic-dropdown" />
                                    </button>
                                    <div className={`dropdown-filter-content ${dropdownShow ? "appear" : ""}`}>
                                        <a href="#">What</a>
                                        <a href="#">are</a>
                                        <a href="#">you</a>
                                        <a href="#">want</a>
                                    </div>
                                </div> */}
                            </div>
                            <div className="dropdown-sort-wrapper">
                                
                                <span className="dropdown-title">Short by</span>
                                <form >
                                    <select 
                                        className="dropdown-filter-content"
                                        onChange={(event) => changeStatus(event.target.value)}
                                        value={currentStatus}
                                    >
                                        <option value="status">All Status</option>
                                        <option value="what">what</option>
                                        <option value="are">are</option>
                                        <option value="you">you</option>
                                        <option value="want">want</option>
                                    </select>
                                </form>
                                {/* <div className="dropdown-status" onClick={() => handleDropdownShow()}>
                                    <button className="btn-dropdown-filter">
                                    No Filter
                                    <img src={ic_drop} alt="ic-dropdown" />
                                    </button>
                                    <div className={`dropdown-fiter-content ${dropdownShow ? "appear" : ""}`}>
                                        <a href="#">What</a>
                                        <a href="#">are</a>
                                        <a href="#">you</a>
                                        <a href="#">want</a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="home-content-wrapper">
                        <div className="home-card-wrapper">
                            <div className="avatar-wrapper">
                                <img src={ic_onic} alt="avatar" />
                            </div>
                            <span className="home-card-title">Onic Token</span>
                            <span className="home-card-desc">1 BNB = 1,710.00 Onic</span>
                            <span className="home-card-body-title">Soft/Hard Cap:</span>
                            <span className="home-card-body-desc">100 BNB - 200 BNB</span>
                            <div className="progress-bar-wrapper">
                                <div className="progress-bar-header">
                                    <span className="progress-bar-title">Progress</span>
                                    <span className="progress-bar-number">62,47%</span>
                                </div>
                                <div className="progress-bar">
                                    <div className="progress-bar-color"></div>
                                </div>
                                <span className="progress-bar-footer">101.23 BNB</span>
                            </div>
                            <div className="home-card-footer-wrapper-1nd">
                                <span className="home-card-footer-tilte">Access:</span>
                                <span className="home-card-footer-desc-1nd">Public</span>
                            </div>
                            <div className="home-card-footer-wrapper-2nd">
                                <span className="home-card-footer-tilte">Chain:</span>
                                <span className="home-card-footer-desc-2nd">Binance Smart Chain</span>
                            </div>
                            <div className="home-card-footer-wrapper-3nd">
                                <span className="home-card-footer-tilte">Liquidity:</span>
                                <span className="home-card-footer-desc-3nd">50%</span>
                            </div>
                            <div className="home-card-footer-wrapper-4nd">
                                <span className="home-card-footer-tilte">Liquidity Lock:</span>
                                <span className="home-card-footer-desc-4nd">30 Days</span>
                            </div>
                            <div className="timer-wrapper">
                                <div className="txt-timer-wrapper">
                                    <span className="timer-title">Sale Ends In:</span>
                                    <span className="timer-number">01 : 02 : 34 : 56</span>
                                </div>
                                <button className="btn-view-ido">View IDO</button>
                            </div>
                        </div>    
                    </div>
                    <div className="pagination-wrapper">
                        <div className="pagination">
                            <a href="#">
                                <img src={arr_left} alt="" />
                            </a>
                            <a href="#" className="active">1</a>
                            <a href="#">2</a>
                            <a href="#">3</a>
                            <a href="#">4</a>
                            <a href="#">5</a>
                            <a href="#">6</a>
                            <a href="#">...</a>
                            <a href="#">25</a>
                            <a href="#">
                                <img src={arr_right} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <div className="c-home-footer">
                    <div className="footer-wrapper">
                        <img src={logo_degem} className="footer-logo" alt="logo-degem" />
                        <span className="footer-desc">Nunc lobortis, nulla vitae porttitor pharetra, odio orci malesuada lorem, id sollicitudin orci tellus in lacus. Duis maximus vitae eros varius pretium. Donec convallis massa ipsum, a dapibus odio aliquam a.Morbi eu aliquam velit, et aliquet nulla. Pellentesque varius mattis maximus.</span>
                        <div className="ic-sosmed-wrapper">
                            <a href="#" className="ic-wrapper ic-twitter">
                                <img src={twt_ico} alt="ic-twitter" />
                            </a>
                            <a href="#" className="ic-wrapper ic-medium">
                                <img src={medium_ico} alt="ic-medium" /> 
                            </a>
                            <a href="#" className="ic-wrapper ic-telegram">
                                <img src={tele_ico} alt="ic-telegram" />
                            </a>
                            <a href="#" className="ic-wrapper">
                                <img src={git_ico} alt="ic-github" />
                            </a>
                        </div>
                        <span className="footer-copyright">All rights reserved ©Degem.io 2021</span>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Ido;
