import './App.css';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

// pages 

import Dapp from './pages/Dapp';
import Gemcave from './pages/Gemcave';
import Exchange from './pages/Exchange';
import Launchpad from './pages/Launchpad';
import Ido from './pages/Ido';
import Treasury from './pages/Treasury';
import Homepage from './pages/Homepage';
import Exchange_landing from './pages/Exchange_landing';
import Gemvault from './pages/Gemvault';





function App() {
  return (
    <Router>
    <div>
        <Route path="/" exact component={Homepage}/>
        <Route path="/exchange" component={Exchange}/>
        <Route path="/launchpad" component={Launchpad}/>
        <Route path="/ido" component={Ido}/>
        <Route path="/treasury" component={Treasury}/>
        <Route path="/gemcave" component={Gemcave}/>
        <Route path="/dapp" component={Dapp}/>
        <Route path="/gemvault" component={Gemvault}/>
        <Route path="/exchange_landing" component={Exchange_landing}/>
    </div>
  </Router>
  );
}

export default App;
