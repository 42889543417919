import React, { Component } from 'react'
import { NavLink } from "react-router-dom";

import '../style/footer.css';
import '../style/global.css';


import logo from '../../assets/asset_launchpad/logo launchpad degem.png';
// aset image logo footer
import menu1sm from '../../assets/asset_global/menu1-sm.svg';
import menu2sm from '../../assets/asset_global/menu2-sm.svg';
import menu3sm from '../../assets/asset_global/menu3-sm.svg';
import menu4sm from '../../assets/asset_global/menu4-sm.svg';
import menu5sm from '../../assets/asset_global/menu5-sm.svg';

export class footer extends Component {
    render() {
        return (
            <div>

                 <div class="section t-footer">
        <div class="container flex-r">
            <div class="foot-line"></div>

            <div class="company-wrap flex-c h-center">
            <NavLink to='/' exact>
                    <img src={logo}/>
            </NavLink>

                <div class="t-16-24 t-center">
                    Have any question?
                </div>
                <button class="cursor t-14-21" style={{background:this.props.color}}>
                    Contact Us
                </button>
            </div>
            <div class="foot-menu-wrap flex-r just-space">
                <div class="foot-menu flex-c h-left">
                    <div class="head t-22-33 t-600">
                        Ecosystem
                    </div>
                    <NavLink to='/exchange_landing'  class="t-18-27 flex-r v-center cursor" exact>
                        <img src={menu1sm}/> Exchange
                    </NavLink>
                    <NavLink to='/treasury'  class="t-18-27 flex-r v-center cursor" exact>
                        <img src={menu5sm}/> Treasury
                    </NavLink>
                    <NavLink to=''  class="t-18-27 flex-r v-center cursor" exact>
                        <img src={menu4sm}/> Refinery
                    </NavLink>
                    <NavLink to='/launchpad'  class="t-18-27 flex-r v-center cursor" exact>
                        <img src={menu2sm}/> Launchpad
                    </NavLink>
                    <NavLink to='/gemcave'  class="t-18-27 flex-r v-center cursor" exact>
                        <img src={menu3sm}/> Gem Cave
                    </NavLink>
         
                </div>
                <div class="foot-menu flex-c h-left">
                    <a class="head t-22-33 t-600 cursor">
                        Roadmap
                    </a>
                </div>
                <div class="foot-menu flex-c h-left">
                    <div class="head t-22-33 t-600">
                        Documentation
                    </div>
                    <a class="t-18-27 cursor">
                        White Paper
                    </a>
                    <a class="t-18-27 cursor">
                        One Pager
                    </a>
                    <a class="t-18-27 cursor">
                        Audit Report
                    </a>
                </div>
            </div>
        </div>
        <div class="container t-18-27 t-center">
            © 2021 degem.io, All rights reserved.
        </div>
    </div>

            </div>
        )
    }
}

export default footer
