import React from 'react';
import { NavLink } from 'react-router-dom';

// style css
import '../components/style/global.css'

// navbarnfooterglobal
import Nav from '../components/comp/navbar.js'
import Footer from '../components/comp/footer.js'

// asset 

import gemtitlle from '../assets/asset_gemcave/gemcave.png'
import logo from '../assets/asset_dApp/degem-left-logo.svg'



const Gemcave = () => {
    require('../css/gemcave.css')

    return (
        <>

        {/* navbar-here */}
        <Nav img={logo} color="#12CC3E"/>

              <div className="section gemcave-landing-sec1">
                <div className="container">

                  {/* <!-- sec-hero --> */}
                  <div className="gemcave-main-box flex-c h-center">
                      <img src={gemtitlle}/>
                      <p className="t-18-27 t-center">
                        The gem cave is our vault of gems. Simply stake degem tokens in the gem cave to receive any current featured tokens as rewards.
                      </p>
                      <NavLink to="/gemvault" class="t-cursor t-18-27" exact>
                      <a>
                        View Pools
                      </a>
                      </NavLink>
                  </div>
                </div>
              </div>

        {/* <!-- sec-footer  --> */}
        <Footer color="#12CC3E"/>
        </>
    );
};

export default Gemcave;