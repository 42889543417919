import React from 'react';
import { NavLink } from 'react-router-dom';

// asset css
import '../components/style/global.css'
// import '../css/launchpad.css'

// navbar footer  global component
import Nav from '../components/comp/navbar.js'
import Footer from '../components/comp/footer.js'

// asset
import logo from '../assets/asset_dApp/degem-left-logo.svg'
import diamond1 from '../assets/asset_launchpad/diamond 1.png';
import diamond2 from '../assets/asset_launchpad/diamond 2.png';
import diamond3 from '../assets/asset_launchpad/diamond 3.png';
import launchpad from '../assets/asset_launchpad/launchpad.png';
import ornamentBtm from '../assets/asset_launchpad/ornamen bottom.png';
import ornamentUp from '../assets/asset_launchpad/ornament up.png';

import tier1leftup from '../assets/asset_launchpad/tier 1 left-up.png';
import tier1rightbtm from '../assets/asset_launchpad/tier 1 right-btm.png';
import tier2leftbtm from '../assets/asset_launchpad/tier 2 left-btm.png';
import tier2rightup from '../assets/asset_launchpad/tier 2 right-up.png';
import tier3leftup from '../assets/asset_launchpad/tier 3 left-up.png';
// import tier3rightbtm from '../assets/asset_launchpad/tier 3 right-btm.png';



const Launchpad = () => {

  require('../css/launchpad.css')

  return (
    <>

{/* navbar-here */}
<Nav img={logo} color="linear-gradient(270deg, #e20deb 0%, #96029b 100%)"/>

<div class="section ">
  <div class="container">
            {/* <!-- sec-hero --> */}
            <div class="sec-hero">
              <img src={ornamentUp} class="bg-ornament"/>
              <img src={ornamentBtm} class="bg-ornament-btm"/>
              
              <div class="launch-main-box flex-c h-center">
              <img src={launchpad}/>
              <p class="t-24-36 p-display t-center">Our unique one-of-a-kind launchpad has a graded tier system suitable for every investor's risk tolerance</p>
                      <div class="btn-wrap">

                        <button class="cursor btn-nav t-18-21 btn-hero-lanch m-btn-right">
                        <NavLink to="" >
                          Apply to Launch
                        </NavLink>
                        </button>
  
                        <button class="cursor btn-nav-border t-18-21 btn-hero-lanch">
                        <NavLink to="/ido">
                          Launch IDO
                        </NavLink>
                        </button>
  
                      </div>
                </div>

              </div>
          </div>
      </div>

{/* sec-tier  */}
<div class="section bg-tier3 margin-top">
  <div class="container">
        


      <div class="section-lanch ">

              <div class="lanch-img">
               <img class="" src={diamond1} alt="diamond-1"/>
              </div>
            <div class="lanch-text">
              <img src={tier1leftup} alt=""  class="react react-left-1"/>
               <img src={tier1rightbtm} alt=""  class="react react-right-1"/>
                 <h1 class="t-tier-tittle">
                 Community launch
                  </h1>
                  <p class="t-tier-p">
                  These projects have made degem’s team excited and bullish. Once we deem a project worthy, we will bring it to the community. We will host AMAs with the teams, and in a “Shark Tank” like fashion, the community will decide what the next gem will be.  
                  But it doesn’t end there! To truly qualify for a Community launch, the project will have to avail itself to every aspect of the Refinery.
                  </p>
            </div>

        </div>
          </div>
      </div>

<div class="section bg-tier2 ">
  <div class="container">
            
<div class="section-lanch mobile">
<div class="lanch-text">
    <img src={tier1leftup} alt=""  class="react react-left-1"/>
    <img src={tier1rightbtm} alt=""  class="react react-right-1"/>
      <h1 class="t-tier-tittle">
      Assessed launch
      </h1>
      <p class="t-tier-p">
      degem's team will do a deeper dive into the project. To qualify for an assessed launch, the project MUST submit to a full and complete audit of the contract.  Additional services will be offered, of course, such as marketing and community development, but only the audit is required to be an Assessed launch.
      </p>
  </div>

<div class="lanch-img">
 <img class="" src={diamond3} alt="diamond-3"/>
</div>

</div>



          </div>
      </div>

      <div class="section bg-tier1">
  <div class="container">
    

<div class="section-lanch ">
      <div class="lanch-img">
        <img class="" src={diamond2} alt="diamond-2"/>
      </div>
      <div class="lanch-text">
        <img src={tier1leftup} alt=""  class="react react-left-1"/>
        <img src={tier1rightbtm} alt=""  class="react react-right-1"/>
          <h1 class="t-tier-tittle">
          Self launch
          </h1>
          <p class="t-tier-p">
          Anyone can launch a contract here. The degem team will run the contract through a token checker to make sure nothing malicious or untoward exists.
          </p>
      </div>
</div>

          </div>
      </div>







{/* <!-- sec-cta --> */}

            <div class="cta">
              <div class="cta-cont">
                <h1 class="t-24-36 p-display t-cta t-400">
                  For a more detailed breakdown of the tier system, its benefits, and why becoming a Tier 1 project should be your goal, please click the link
                </h1>
                <button class="btn-cta btn-nav t-18-21">
                  launchpad paper
                </button>
              </div>
            </div>

            




      {/* footerhere  */}
      <Footer color="linear-gradient(270deg, #e20deb 0%, #96029b 100%)"/>
    </>
  );
};

export default Launchpad;